<template>
  <div class="login">
    <Navbar></Navbar>
    <CampSearch :camps="camps"></CampSearch>
  </div>
</template>

<script>
import moment from 'moment'
import CampFinder from '@/api-services/finder.service.js';
import CampSearch from '../components/ParentDashboard/CampsSearch.vue'
import Navbar from '@/components/Navbar.vue'
// import Navbar from '../components/Navbar.vue'
export default {
  name: 'ParentDashboard',
  components: {
    'CampSearch': CampSearch,
    'Navbar' : Navbar
  },
  data () {
    return {
      t: this.$cookies.get('cit'),
      camps: []
    }
  },
  mounted() {
    CampFinder.findCamps(this.t)
    .then((response) => {
      let formatedCamps = this.FormatDates(response.data)
      this.camps = formatedCamps
    })
  },
  methods: {
    FormatDates(camps) {
      let formatedCamps = camps
      for(let i = 0; i<camps.length; i++) {
        formatedCamps[i].startDate = moment(camps[i].startDate).format('YYYY-MM-DD')
        formatedCamps[i].endDate = moment(camps[i].endDate).format('YYYY-MM-DD')
      }
      return formatedCamps
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #input-usage .v-input__prepend-outer,
  #input-usage .v-input__append-outer,
  #input-usage .v-input__slot,
  #input-usage .v-messages {
    border: 1px dashed rgba(0,0,0, .4);
  }
</style>
