<template>
  <div>
    <v-container>
      <v-row>
        <p>Filter by camp season dates, or specific dates to fill your summer camp itinerary. Use the age filters to find programs to serve multiple children like siblings or buddies that are different ages.</p>
      </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="3" class="mt-4">
        <v-btn @click="Filter" width="80%" class="mt-2">Find Camps</v-btn>
        <v-btn @click="Clear" width="80%" class="mt-2">Clear Search</v-btn>
      </v-col>
      <v-col cols="12" md="3" sm="6" >
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y 
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Start Date"
              :value="startDate"
              v-on="on"
              class="mr-9"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="startDate"
            no-title
            @input="startDateMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y 
          
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="End Date"
              :value="endDate"
              class="mr-9"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="endDate"
            no-title
            @input="endDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" sm="6" >
          <v-text-field label="Start Time" v-model="startTime" type="time" class="mr-9"></v-text-field>
          <v-text-field label="End Time" v-model="endTime" type="time" class="mr-9"></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6" >
          <v-text-field label="Youngest Age" v-model="ageYoungest" type="number" class="mr-9"></v-text-field>
          <v-text-field label="Oldest Age" v-model="ageOldest" type="number" class="mr-9"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col >
        <v-checkbox
          v-model="beforeCareCheckbox"
          label="Before Care"
        ></v-checkbox>
      </v-col>
      <v-col >
        <v-checkbox
          v-model="afterCareCheckbox"
          label="After Care"
        ></v-checkbox>
      </v-col>
      <v-col >
        <v-checkbox
          v-model="overnightCheckbox"
          label="Overnight"
        ></v-checkbox>
      </v-col>
      <v-col >
        <v-checkbox
          v-model="busCheckbox"
          label="Bus"
        ></v-checkbox>
      </v-col>
      <v-col >
        <v-checkbox
          v-model="mealsCheckbox"
          label="Meals"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col class="bannerAd">Banner Ad Here</v-col> -->
      <v-col cols="12" sm="12" md="12" lg="12"
            v-for="ad in bannerAds"
            :key="ad.title"
          >
            <div class="bannerAd">
              <v-img :src="ad.url"></v-img>
            </div>
          </v-col>
    </v-row>
    <v-row >
      
      <v-col cols="12" sm="12" md="8" lg="8" >
        <v-data-table
        :headers="headers"
        :items="filteredCamps"
        @click:row="showMore"
        class="elevation-1"
        >
        <template v-slot:headers>
          <v-tooltip top>
            <template v-slot:activator="{on}">
            <span v-on="on">
              Sort By
            </span>
            
          </template>
          </v-tooltip>
        </template>
        
        <template v-slot:item.campURL="{item}">
          <v-btn @click="BookIt(item)">BookIt</v-btn>
        </template>
      </v-data-table>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" >
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12"
            v-for="ad in ads"
            :key="ad.title"
          >
              <v-img :src="ad.url" @click="OpenNewTab(ad.link)" class="adImage"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
    v-if="campInfoDialog"
    v-model="campInfo"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
      <SessioninfoDialog :campInfo="campInfo" @campInfoDialog="campInfoDialog = false"></SessioninfoDialog>
    </v-dialog>
    </div>
</template>
<script>
import SessioninfoDialog from '@/components/SessionInfoDialog.vue'
import azure from '@/api-services/azure.service.js'

export default {
  name: 'CampSearch',
  data () {
    return {
      search: "",
      isMobile: false,
      campInfoDialog: false,
      campInfo: {},
      filteredCamps: this.camps,
      selected: [],
      headers: [{ text:'Camp', value: 'campName'}, {text: 'Start Date', value: 'startDate'}, {text:'End Date', value: 'endDate'}, {text: 'Title', value: 'title'}, {text: 'BookIt', value: 'campURL'}],
      startDateMenu: false,
      startDate: null,
      endDateMenu: false,
      endDate: null,
      minDate: null,
      age: null,
      ageYoungest: null,
      ageOldest: null,
      startTime: null,
      endTime: null,
      overnightCheckbox: false,
      busCheckbox: false,
      mealsCheckbox: false,
      beforeCareCheckbox: false,
      afterCareCheckbox: false,
      ads: [ ],
      bannerAds: []
    }
  },
  props: ['camps'],
  components: {
    'SessioninfoDialog': SessioninfoDialog
  },
  async mounted() {
    const adUrls = await azure.GetAds(1,3);
    this.ads = adUrls.data.map(ad => {
      return {
        title: ad.fileName,
        url: ad.url,
        link: ad.link
      }
    })

    const bannerAdUrls = await azure.GetAds(2, 1);
    this.bannerAds = bannerAdUrls.data.map(ad => {
      return {
        title: ad.fileName,
        url: ad.url
      }
    })



  },
  methods: {
    Filter(){
      this.filteredCamps = this.camps
      let filtered = this.FilterStartDate(this.camps)
      filtered = this.FilterEndDate(filtered)
      filtered = this.FilterAge(filtered)
      filtered = this.HoursFilter(filtered)
      filtered = this.filterOvernight(filtered)
      filtered = this.filterBus(filtered)
      filtered = this.filterMeals(filtered)
      filtered = this.filterBeforeCare(filtered)
      filtered = this.filterAfterCare(filtered)
      this.filteredCamps = filtered
    },
    Clear() {
      this.filteredCamps = this.camps
      this.startTime = null
      this.endTime = null
      this.endDate = null
      this.startDate = null
      this.ageYoungest = null
      this.ageOldest = null
      this.overnightCheckbox = false
      this.busCheckbox = false
      this.mealsCheckbox = false
      this.beforeCareCheckbox = false
      this.afterCareCheckbox = false
    },
    FilterStartDate(camps) {
      let filteredCamps = camps
      if(this.startDate) {
        filteredCamps = camps.filter(el => el.startDate >= this.startDate)
      }
      return filteredCamps
    },
    FilterEndDate(camps) {
      let filteredCamps = camps
      if(this.endDate) {
        filteredCamps = camps.filter(el => el.endDate <= this.endDate)
      }
      return filteredCamps
    },
    FilterAge(camps) {
      let filteredCamps = camps
      if(this.ageYoungest && this.ageOldest) {
        filteredCamps = camps.filter(el => el.ageYoungest <= parseInt(this.ageYoungest) && el.ageOldest >= parseInt(this.ageOldest))
      } else if (this.ageYoungest && !this.ageOldest) {
        filteredCamps = camps.filter(el => el.ageYoungest <= parseInt(this.ageYoungest))
      } else if (!this.ageYoungest && this.ageOldest) {
        filteredCamps = camps.filter(el => el.ageOldest >= parseInt(this.ageOldest))
      }
        return filteredCamps
    },
    HoursFilter(camps) {
      let filteredCamps = camps
      if(this.startTime){
        filteredCamps = this.filterStartTime(filteredCamps)
      }

      if(this.endTime) {
         filteredCamps = this.filterEndTime(filteredCamps)
      }
      return filteredCamps
    },
    filterStartTime(camps) {
      let startTimeFiltedCamps = []
      for(var i = 0; i<camps.length; i++) {
        if(camps[i].beforeCareStart) {
          if(camps[i].beforeCareStart <= this.startTime) { startTimeFiltedCamps.push(camps[i])}
        } else {
          if(camps[i].campStartTime <= this.startTime) { startTimeFiltedCamps.push(camps[i])}
        }
      }
      return startTimeFiltedCamps
    },
    filterEndTime(camps) {
      let endTimeFiltedCamps = []
      for(let i = 0; i<camps.length; i++) {
        if(camps[i].afterCareEnd) {
          if(camps[i].afterCareEnd >= this.endTime) { endTimeFiltedCamps.push(camps[i])}
        } else {
          if(camps[i].campEndTime >= this.endTime) { endTimeFiltedCamps.push(camps[i])}
        }
      }
      return endTimeFiltedCamps
    },
    filterOvernight(camps) {
      let filteredCamps = camps
      if(this.overnightCheckbox) {
        filteredCamps = camps.filter(el => el.overnight)
      }
        return filteredCamps
    },
    filterBus(camps) {
      let filteredCamps = camps
      if(this.busCheckbox) {
        filteredCamps = camps.filter(el => el.bus)
      }
        return filteredCamps
    },
    filterMeals(camps) {
      let filteredCamps = camps
      if(this.mealsCheckbox) {
        filteredCamps = camps.filter(el => el.meals)
      }
        return filteredCamps
    },
    filterBeforeCare(camps) {
      let filteredCamps = camps
      if(this.beforeCareCheckbox) {
        filteredCamps = camps.filter(el => el.beforeCare)
      }
        return filteredCamps
    },
    filterAfterCare(camps) {
      let filteredCamps = camps
      if(this.afterCareCheckbox) {
        filteredCamps = camps.filter(el => el.afterCare)
      }
        return filteredCamps
    },
    BookIt(camp){
      const hasHttps = camp.bookItUrl.split(':')[0] 
      const url = hasHttps === 'https' ? camp.bookItUrl : `https://${camp.bookItUrl}`
      window.open(url, "_blank")
    },
    OpenNewTab(url) {
      window.open(url, "_blank")
    },
    showMore(session) {
      this.campInfo = session
      this.campInfoDialog = true
    },
    closeCampInfo() {
      this.campInfo = {}
      this.campInfoDialog = false
    }
  },
  watch: {
    camps(newVal) {
      this.filteredCamps = newVal
    }
  }
}
</script>

<style scoped>
  .v-btn {
    background-color: #689F38 !important;
    border-radius: 3px;
    color: #ffffff;
  }

  .v-list-item__content {
    text-align: left;
  }

/* This is the camp session dialog box  */
  .campSessionInfo--img {
    max-height: 90%;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .campSessionInfo--card {
    height: 300px;
  }

  
    .adImage
    {
       display: block;
       width: 300px;
       height: 250px;
    }
    

    
    

    .bannerAd {
    background-color: #689F38;
  }
</style>
